<template>
  <div class="auth-wrapper auth-v2 bg">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0 auth-bg-left">
          <v-container fill-height grid-list-md text-xs-center>
            <v-layout row wrap align-center>
              <v-flex>
                <div class="left-text-container">
                  <div class="pa-16 pe-0 pt-8 pb-8 left-text-title">
                    The payment platform<br />you were waiting for.
                  </div>
                  <div class="pa-16 pe-0 pt-0 left-text-subtitle">
                    Our robust and flexible technology makes payments so seamless you don’t even need to think about
                    them
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-col>
        <v-col lg="4" class="align-center auth-bg-right pa-5 pb-0">
          <v-container>
            <div class="brand-logo">
              <img :src="appLogo" alt="Key2Pay"/>
            </div>
          </v-container>
          <v-container fill-height grid-list-md text-xs-center>
            <v-layout row wrap align-center>
              <v-flex>
                <v-card flat light>
                  <v-card-title> Forgot your password? </v-card-title>
                  <v-card-subtitle>
                    Enter your email and we'll send you instructions to reset your password
                  </v-card-subtitle>
                  <v-card-text>
                    <v-form ref="passwordRecoveryForm" v-model="isValid" @submit.prevent="handleFormSubmit">
                      <v-text-field
                        v-model="email"
                        :rules="[validators.required, validators.emailValidator]"
                        outlined
                        label="Enter your e-mail address"
                        placeholder="john@example.com"
                        hide-details
                        min-height="56"
                        class="mb-5"
                      ></v-text-field>

                      <div class="mb-5 pl-0">
                        <VueRecaptcha
                          ref="vueRecaptchaCtl"
                          :sitekey="siteKey"
                          :load-recaptcha-script="true"
                          @verify="handleReCaptchaSucess"
                          @error="handleReCaptchaError"
                          @expired="handleExpired"
                        >
                        </VueRecaptcha>
                      </div>

                      <v-btn
                        min-height="56"
                        block
                        color="primary"
                        type="submit"
                        :loading="loading"
                        :disabled="loading || reCaptchaToken == null"
                      >
                       Submit
                      </v-btn>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center align-center">
                    <!-- forget link -->
                    <router-link :to="{ name: 'auth-login' }" class="d-flex align-center text-sm forgot-password">
                      <span>Back to Login</span>
                    </router-link>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
            <v-snackbar v-model="snackbar.isSnackbarVisible">
              <span v-html="snackbar.snackbarMessage"></span>
              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar.isSnackbarVisible = false"> Close </v-btn>
              </template>
            </v-snackbar>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import { emailValidator, required } from '@core/utils/validation'
import { mdiChevronLeft } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha,
  },
  setup() {
    const siteKey = computed(() => process.env.VUE_APP_RECAPTCHA_SITEKEY)
    const reCaptchaToken = ref(null)
    const vueRecaptchaCtl = ref(null)
    const passwordRecoveryForm = ref(null)
    const loading = ref(false)
    const isPasswordVisible = ref(false)
    const email = ref('')
    const isValid = ref(false)

    const snackbar = ref({
      isSnackbarVisible: false,
      snackBarColor: 'info',
      snackbarMessage: '',
      snackbarTimeOut: '3000',
    })

    const validate = () => {
      passwordRecoveryForm.value.validate()
    }

    const handleReCaptchaError = () => {
      reCaptchaToken.value = null
    }

    const handleExpired = () => {
      reCaptchaToken.value = null
    }

    const handleReCaptchaSucess = response => {
      reCaptchaToken.value = response
    }

    const showSnackbarMessage = data => {
      snackbar.value.isSnackbarVisible = true
      snackbar.value.snackBarColor = data.type
      snackbar.value.snackbarMessage = data.message
    }

    const handleFormSubmit = () => {
      if (isValid.value) {
        loading.value = true

        axios
          .post(`/userAccess/authentication/password-reset-link?email=${email.value}&token=${reCaptchaToken.value}`)
          .then(() => {
            showSnackbarMessage({
              type: 'info',
              message: 'The instructions to reset you password have been sent to the registered e-mail',
            })
            vueRecaptchaCtl.value.reset()
            reCaptchaToken.value = null
            email.value = ''
          })
          .catch(error => {
            showSnackbarMessage({ message: error.response, type: 'error' })
          })
          .finally(() => {
            loading.value = false
          })
      } else {
        validate()
      }
    }

    return {
      handleFormSubmit,
      showSnackbarMessage,
      validate,
      handleReCaptchaError,
      handleReCaptchaSucess,
      handleExpired,
      siteKey,
      reCaptchaToken,
      vueRecaptchaCtl,
      loading,
      isPasswordVisible,
      email,
      snackbar,
      isValid,

      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      passwordRecoveryForm,
      icons: {
        mdiChevronLeft,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/auth.scss';
</style>
